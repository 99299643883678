<template>
    <div>
        <h1>Gene expression profiles </h1>
        <h2>Tissue/Cell-level distribution</h2>
        <div class="dropdown-container">
            <select v-model="selectedSCDataset" @change="handleDatasetChange" class="dropdown-select">
                <option disabled value="">Select</option>
                <option v-for="scDataset in scDatasets" :key="scDataset">{{ scDataset }}</option>
            </select>
            <v-select v-model="selectedSCGene" :options="filteredSCGenes" @search="filterSCGenes"
                @change="handleGeneChange" :placeholder="scGenePlaceholder" :reduce="gene => gene" :input-debounce="300"
                class="dropdown-select" :disabled="!selectedSCDataset || isFetchingGenes" />
            <select v-model="selectedAttribute" @change="fetchCategoryInfo" class="dropdown-select">
                <option disabled value="">Select Attribute</option>
                <option v-for="attribute in attributes" :key="attribute">
                    {{ attribute }}</option>
            </select>
        </div>
        <div class="charts-container">
            <div class="chart-container">
                <div ref="scScatter" style="width: 100%; height: 600px;"></div>
            </div>
            <div class="chart-container">
                <div ref="scBoxplot" style="width: 100%; height: 600px;"></div>
            </div>
        </div>
        <h2>Cell-specific expression comparison</h2>
        <div class="dropdown-container">
            <select v-model="selectedCellType" @change="fetchCellSpecificCancerTypes" class="dropdown-select">
                <option disabled value="">Select</option>
                <option v-for="cellType in cellTypes" :key="cellType">{{ cellType }}</option>
            </select>
            <multiselect v-model="selectedCancerTypes" :options="cellSpecificCancerTypes" :multiple="true"
                :close-on-select="false" @close="handleCancerTypeSelectionChange"
                @remove="handleCancerTypeSelectionChange" placeholder="Select cancer types" class="dropdown-select">
            </multiselect>
            <v-select v-model="selectedGeneForComparison" :options="filteredGenesForComparison"
                @search="filterGenesForComparison" @change="handleGeneForComparisonChange"
                :placeholder="geneForComparisonPlaceholder" :reduce="gene => gene" :input-debounce="300"
                class="dropdown-select" :disabled="!selectedCancerTypes || isFetchingGeneForComparison" />
        </div>
        <div ref="comparisonBoxplot" style="width: 100%; height: 600px; margin-top: 20px;"></div>
        <h2>Bulk data-based distribution</h2>
        <div class="dropdown-container">
            <select v-model="selectedBulkDataset" @change="fetchBulkFiles" class="dropdown-select">
                <option disabled value="">Select</option>
                <option v-for="bulkDataset in bulkDatasets" :key="bulkDataset">{{ bulkDataset }}</option>
            </select>
            <v-select v-model="selectedBulkFile" :options="filteredBulkFiles" @search="filterBulkFiles"
                @change="fetchBulkExpressions" placeholder="Type to search for genes" :reduce="file => file"
                :input-debounce="300" class="dropdown-select" :disabled="!selectedBulkDataset" />
        </div>
        <div ref="bulkBoxplot" style="width: 100%; height: 600px; margin-top: 20px;"></div>
    </div>
</template>

<script>
import axios from 'axios';
import * as echarts from 'echarts';
import vSelect from 'vue3-select';
import Multiselect from 'vue-multiselect';
import pako from 'pako';
import seedrandom from 'seedrandom';
import 'vue3-select/dist/vue3-select.css';
import 'vue-multiselect/dist/vue-multiselect.css';

export default {
    components: { vSelect, Multiselect },
    data() {
        return {
            bulkDatasets: [],
            bulkFiles: [],
            filteredBulkFiles: [],
            selectedBulkDataset: '',
            selectedBulkFile: '',
            scDatasets: [],
            scGenes: [],
            filteredSCGenes: [],
            selectedSCDataset: '',
            selectedSCGene: '',
            scUMAPTemplate: [],
            scUMAP: [],
            scValues: [],
            minSCValue: 0,
            maxSCValue: 10,
            attributes: ['presig_cell_type', 'presig_dataset_id', 'presig_lesion_orig', 'presig_lesion_revised', 'presig_cancer_source'],
            selectedAttribute: '',
            cellTypes: [],
            cellSpecificCancerTypes: [],
            selectedCellType: '',
            selectedCancerTypes: [],
            commonGenes: [],
            filteredGenesForComparison: [],
            selectedGeneForComparison: '',
            fetchSCGenesAbortController: null,
            fetchSCExpressionsAbortController: null,
            fetchGeneForComparisonAbortController: null,
            isFetchingGenes: false,
            isFetchingGeneForComparison: false,
        };
    },
    computed: {
        scGenePlaceholder() {
            return this.isFetchingGenes ? 'Loading...' : 'Type to search for genes';
        },
        geneForComparisonPlaceholder() {
            return this.isFetchingGeneForComparison ? 'Loading...' : 'Type to search for genes';
        }
    },
    mounted() {
        this.initialize();
        window.addEventListener('resize', this.adjustScatterPlotSize);
        this.$nextTick(this.adjustScatterPlotSize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.adjustScatterPlotSize);
        this.disposeCharts();
    },
    methods: {
        async initialize() {
            this.selectedSCDataset = 'BRCA',
                this.selectedCellType = 'B/Plasma cells',
                this.selectedCancerTypes = ['BRCA', 'CESC'],
                this.selectedGeneForComparison = 'A1BG-AS1',
                this.selectedBulkDataset = 'GSE109743',
                this.selectedBulkFile = 'A1BG-AS1',
                await Promise.all([
                    this.handleGeneForComparisonChange(),
                    this.fetchBulkExpressions(),
                    this.fetchSCGenes(),
                    this.fetchSCDatasets(),
                    this.fetchCellTypes(),
                    this.fetchBulkDatasets(),
                ]);
        },
        async fetchBulkDatasets() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/bulkdatasets`, {
                    params: {
                        type: 'bulk'
                    }
                });
                this.bulkDatasets = response.data.bulkDatasets;
                if (!this.bulkDatasets.has(this.selectedBulkDataset)) {
                    this.selectedBulkDataset = this.bulkDatasets[0];
                }
                this.fetchBulkFiles();
            } catch (error) {
                console.error('Error fetching cancer types:', error);
            }
        },
        filterBulkFiles(search) {
            if (search.length < 2) {
                this.filteredBulkFiles = []; // 输入少于两个字符时不显示任何选项
            } else {
                const searchLower = search.toLowerCase();
                this.filteredBulkFiles = this.bulkFiles.filter(file =>
                    file.toLowerCase().includes(searchLower)
                );
            }
        },
        async fetchBulkFiles() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/bulkfiles`, {
                    params: {
                        type: 'bulk',
                        bulkDataset: this.selectedBulkDataset
                    }
                });
                this.bulkFiles = response.data.bulkFiles;
                this.filteredBulkFiles = [];
                if (!this.bulkFiles.includes(this.selectedBulkFile)) {
                    this.selectedBulkFile = this.bulkFiles[0];
                }
                this.fetchBulkExpressions();
            } catch (error) {
                console.error('Error fetching metadata:', error);
            }
        },
        async fetchBulkExpressions() {
            try {
                const bulkResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/bulkexpressions`, {
                    params: {
                        type: 'bulk',
                        bulkDataset: this.selectedBulkDataset,
                        bulkFile: this.selectedBulkFile
                    },
                    responseType: 'arraybuffer'
                });

                const decompressedData = pako.ungzip(new Uint8Array(bulkResponse.data), { to: 'string' });
                const rows = decompressedData.trim().split('\n');
                const dataByCategory = {};

                rows.forEach(row => {
                    const [value, category] = row.split('\t');
                    if (!dataByCategory[category]) {
                        dataByCategory[category] = [];
                    }
                    dataByCategory[category].push(parseFloat(value));
                });

                const chart = echarts.init(this.$refs.bulkBoxplot);
                const title = 'Box Plot of Bulk Expressions by Category';
                this.renderBulkBoxplot(dataByCategory, chart, title);
            } catch (error) {
                console.error('Error fetching cancer genes:', error);
            }
        },
        async renderBulkBoxplot(dataByCategory, chart, title) {
            const categories = Object.keys(dataByCategory);

            const boxData = [];
            const tooltipData = [];
            const outliersData = [];

            categories.forEach((category, index) => {
                const values = dataByCategory[category];
                values.sort((a, b) => a - b);
                const rawMin = values[0];
                const rawMax = values[values.length - 1];
                const q1 = values[Math.floor(values.length / 4)];
                const median = values[Math.floor(values.length / 2)];
                const q3 = values[Math.floor(values.length * 3 / 4)];
                const iqr = q3 - q1;
                const lowerFence = q1 - 1.5 * iqr;
                const upperFence = q3 + 1.5 * iqr;

                const actualMin = values.find(v => v >= lowerFence) || rawMin;
                const actualMax = values.reverse().find(v => v <= upperFence) || rawMax;

                // Data for box plot
                boxData.push([actualMin, q1, median, q3, actualMax]);

                // Extended data for tooltip
                tooltipData.push([
                    index,        // category index
                    actualMin,    // actual minimum
                    q1,           // first quartile
                    median,       // median
                    q3,           // third quartile
                    actualMax,    // actual maximum
                    rawMin,       // raw minimum
                    rawMax        // raw maximum
                ]);

                // Outliers
                const outlierPoints = values.filter(value => value < lowerFence || value > upperFence).map(value => {
                    return [index, value]; // Use category index as the x-coordinate
                });
                outliersData.push(...outlierPoints);
            });

            const option = {
                title: {
                    text: title,
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        if (params.componentType === 'series' && params.seriesType === 'scatter') {
                            return `Category: ${categories[params.data[0]]}<br/>Outlier: ${params.data[1]}`;
                        } else if (params.componentType === 'series' && params.seriesType === 'boxplot') {
                            const data = tooltipData[params.dataIndex];
                            return `
                        Category: ${categories[data[0]]}<br/>
                        Min (Actual): ${data[1]}<br/>
                        Q1: ${data[2]}<br/>
                        Median: ${data[3]}<br/>
                        Q3: ${data[4]}<br/>
                        Max (Actual): ${data[5]}<br/>
                        Min (Raw): ${data[6]}<br/>
                        Max (Raw): ${data[7]}
                    `;
                        }
                    }
                },
                xAxis: {
                    type: 'category',
                    data: categories
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: 'Bulk Expression',
                        type: 'boxplot',
                        data: boxData,
                        itemStyle: {
                            borderWidth: 2
                        },
                        boxWidth: ['15%', '30%'],
                        emphasis: {
                            itemStyle: {
                                borderColor: '#c23531',
                                borderWidth: 3,
                                shadowBlur: 5,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                    },
                    {
                        name: 'Outliers',
                        type: 'scatter',
                        data: outliersData
                    }
                ]
            };
            chart.setOption(option);
        },
        async fetchSCDatasets() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/scdatasets`);
                this.scDatasets = response.data.scDatasets;
            } catch (error) {
                console.error('Error fetching cancer types:', error);
            }
        },
        filterSCGenes(search) {
            if (search.length < 2) {
                this.filteredSCGenes = []; // 输入少于两个字符时不显示任何选项
            } else {
                const searchLower = search.toLowerCase();
                this.filteredSCGenes = this.scGenes.filter(gene =>
                    gene.toLowerCase().includes(searchLower)
                );
            }
        },
        async fetchSCGenes() {
            this.isFetchingGenes = true;
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/scgenes`, {
                    params: {
                        scDataset: this.selectedSCDataset
                    }
                });
                this.scGenes = response.data.scGenes;
                this.filteredSCGenes = [];
                const umapResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/scumaps`, {
                    params: {
                        scDatasets: this.selectedSCDataset
                    },
                    responseType: 'arraybuffer'
                });

                const decompressedData = pako.ungzip(new Uint8Array(umapResponse.data), { to: 'string' });
                const rows = decompressedData.trim().split('\n');
                const headers = rows[0].split('\t');

                // 在转化为字典的过程中添加 expression 字段
                this.scUMAPTemplate = rows.slice(1).map(row => {
                    const values = row.split('\t');
                    const item = headers.reduce((obj, header, index) => {
                        obj[header] = values[index];
                        return obj;
                    }, {});
                    item['expression'] = 0; // 添加 expression 字段，值为 0
                    return item;
                });
                if (!this.selectedSCGene | !this.scGenes.includes(this.selectedSCGene)) {
                    this.selectedSCGene = this.scGenes[10];
                }
                this.fetchSCExpressions();
            } catch (error) {
                console.error('Error fetching metadata:', error);
            } finally {
                this.isFetchingGenes = false;
            }
        },
        async fetchSCExpressions() {
            try {
                this.scUMAP = this.scUMAPTemplate.map(item => ({ ...item }));
                const scResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/scexpressions`, {
                    params: {
                        scDataset: this.selectedSCDataset,
                        scGenes: this.selectedSCGene
                    },
                    responseType: 'arraybuffer'
                });

                const decompressedData = pako.ungzip(new Uint8Array(scResponse.data), { to: 'string' });

                const lines = decompressedData.split('\n').slice(0, -1);
                const headers = ['barcode', 'expression'];
                const scExpressions = lines.map(line => {
                    const values = line.split('\t').slice(0, 2);
                    const expression = {};
                    headers.forEach((header, i) => {
                        expression[header] = values[i];
                    });
                    return expression;
                });

                const scExpressionMap = new Map();
                scExpressions.forEach(({ barcode, expression }) => {
                    scExpressionMap.set(barcode, expression);
                });
                this.scUMAP.forEach(item => {
                    if (scExpressionMap.has(item.barcode)) {
                        item.expression = scExpressionMap.get(item.barcode);
                    }
                });
                this.scValues = this.scUMAP.map(item => Number(item.expression));
                this.maxSCValue = this.scValues.length ? this.scValues.reduce((max, val) => val > max ? val : max, -Infinity) : 0;
                this.minSCValue = this.scValues.length ? this.scValues.reduce((min, val) => val < min ? val : min, Infinity) : 0;
                this.selectedAttribute = this.attributes[0];
                this.fetchCategoryInfo();
                this.renderSCScatter(this.$refs.scScatter);
            } catch (error) {
                console.error('Error fetching cancer genes:', error);
            }
        },
        async renderSCScatter(chartObj) {
            if (!chartObj) return;

            const rng = seedrandom('fixed-seed');
            let data = this.scUMAP.slice();
            if (data.length > 10000) {
                for (let i = data.length - 1; i > 0; i--) {
                    const j = Math.floor(rng() * (i + 1));
                    [data[i], data[j]] = [data[j], data[i]];
                }
                data = data.slice(0, 10000);
            }

            if (!chartObj) return;

            if (this.scatterChart) {
                this.scatterChart.dispose();
            }
            this.scatterChart = echarts.init(chartObj);

            const maxValue = Math.ceil(this.maxSCValue);
            const minValue = Math.floor(this.minSCValue);
            const containerWidth = chartObj.clientWidth;
            const containerHeight = chartObj.clientHeight;
            const size = Math.min(containerWidth, containerHeight) - 100;
            const left = (containerWidth - size) / 2;

            const option = {
                title: {
                    text: `Scatter Plot of ${this.selectedSCGene} Expression`,
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        return `${params.marker} ${params.data.value[2].toFixed(4)}: [${params.data.value[0].toFixed(4)}, ${params.data.value[1].toFixed(4)}]`;
                    }
                },
                grid: {
                    left: `${left}px`,
                    right: `${left}px`,
                    bottom: '10%',
                    containLabel: true,
                    width: size,
                    height: size,
                },
                xAxis: {
                    type: 'value',
                    scale: true,
                    axisLine: { show: false },
                    axisTick: { show: false },
                    splitLine: { show: true }
                },
                yAxis: {
                    type: 'value',
                    scale: true,
                    axisLine: { show: false },
                    axisTick: { show: false },
                    splitLine: { show: true }
                },
                visualMap: {
                    show: true,
                    type: 'continuous',
                    calculable: true,
                    min: minValue,
                    max: maxValue,
                    color: ['red', 'orange', 'yellow', 'grey'],
                    textStyle: {
                        color: '#333'
                    }
                },
                series: [
                    {
                        name: 'Gene Expression',
                        type: 'scatter',
                        data: data.map(item => ({
                            value: [
                                parseFloat(item['umap_1']),
                                parseFloat(item['umap_2']),
                                parseFloat(item['expression'])
                            ],
                            symbolSize: item['expression'] == 0 ? 2 : 3
                        })),
                    }
                ]
            };
            this.scatterChart.setOption(option);
        },
        async fetchCategoryInfo() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/categoryinfo`, {
                    params: {
                        tableName: `${this.selectedSCDataset.toLowerCase()}s`,
                        attribute: this.selectedAttribute
                    },
                    responseType: 'arraybuffer'
                });
                const decompressedData = pako.ungzip(new Uint8Array(response.data), { to: 'string' });
                const categoryInfoRows = decompressedData.split('\n');
                const category = new Map();

                categoryInfoRows.forEach(row => {
                    const values = row.split('\t');
                    category.set(values[0], values[1]);
                });
                console.log(category)
                const dataByCategory = {};
                const countByCategory = {};

                this.scUMAP.forEach(item => {
                    const barcode = item['barcode'];
                    const categoryValue = category.get(barcode);
                    if (!categoryValue) console.log('No category value found for barcode:', barcode);
                    if (!dataByCategory[categoryValue]) {
                        dataByCategory[categoryValue] = [];
                    }
                    countByCategory[categoryValue] = (countByCategory[categoryValue] || 0) + 1;
                    if (item['expression'] != 0) {
                        dataByCategory[categoryValue].push(parseFloat(item['expression']));
                    }
                });

                const chart = echarts.init(this.$refs.scBoxplot);
                const title = `Box Plot of ${this.selectedSCGene} Expression by ${this.selectedAttribute} (excluding zero values)`;
                this.renderSCBoxplot(dataByCategory, chart, title);

            } catch (error) {
                console.error('Error fetching category info:', error);
            }
        },
        renderSCBoxplot(dataByCategory, chart, title) {
            const categories = Object.keys(dataByCategory);

            const boxData = [];
            const tooltipData = [];
            const outliersData = [];

            categories.forEach((category, index) => {
                const values = dataByCategory[category];
                values.sort((a, b) => a - b);
                const rawMin = values[0];
                const rawMax = values[values.length - 1];
                const q1 = values[Math.floor(values.length / 4)];
                const median = values[Math.floor(values.length / 2)];
                const q3 = values[Math.floor(values.length * 3 / 4)];
                const iqr = q3 - q1;
                const lowerFence = q1 - 1.5 * iqr;
                const upperFence = q3 + 1.5 * iqr;

                const actualMin = values.find(v => v >= lowerFence) || rawMin;
                const actualMax = values.reverse().find(v => v <= upperFence) || rawMax;

                // Data for box plot
                boxData.push([actualMin, q1, median, q3, actualMax]);

                // Extended data for tooltip
                tooltipData.push([
                    index,        // category index
                    actualMin,    // actual minimum
                    q1,           // first quartile
                    median,       // median
                    q3,           // third quartile
                    actualMax,    // actual maximum
                    rawMin,       // raw minimum
                    rawMax        // raw maximum
                ]);

                // Outliers
                const outlierPoints = values.filter(value => value < lowerFence || value > upperFence).map(value => {
                    return [index, value]; // Use category index as the x-coordinate
                });
                outliersData.push(...outlierPoints);
            });

            const option = {
                title: {
                    text: title,
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        if (params.componentType === 'series' && params.seriesType === 'scatter') {
                            return `Category: ${categories[params.data[0]]}<br/>Outlier: ${params.data[1]}`;
                        } else if (params.componentType === 'series' && params.seriesType === 'boxplot') {
                            const data = tooltipData[params.dataIndex];
                            return `
                        Category: ${categories[data[0]]}<br/>
                        Min (Actual): ${data[1]}<br/>
                        Q1: ${data[2]}<br/>
                        Median: ${data[3]}<br/>
                        Q3: ${data[4]}<br/>
                        Max (Actual): ${data[5]}<br/>
                        Min (Raw): ${data[6]}<br/>
                        Max (Raw): ${data[7]}
                    `;
                        }
                    }
                },
                xAxis: {
                    type: 'category',
                    data: categories,
                    axisLabel: {
                        rotate: 45
                    }
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: 'Bulk Expression',
                        type: 'boxplot',
                        data: boxData,
                        itemStyle: {
                            borderWidth: 2
                        },
                        boxWidth: ['15%', '30%'],
                        emphasis: {
                            itemStyle: {
                                borderColor: '#c23531',
                                borderWidth: 3,
                                shadowBlur: 5,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                    },
                    {
                        name: 'Outliers',
                        type: 'scatter',
                        data: outliersData
                    }
                ]
            };
            chart.setOption(option);
        },
        async fetchCellTypes() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/celltypes`);
                this.cellTypes = response.data.cellTypes;
                this.fetchCellSpecificCancerTypes();
            } catch (error) {
                console.error('Error fetching cell types:', error);
            }
        },
        async fetchCellSpecificCancerTypes() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/cellspecificcancertypes`, {
                    params: {
                        cellType: this.selectedCellType
                    }
                });
                this.cellSpecificCancerTypes = response.data.cancerTypes;
                if (!this.cellSpecificCancerTypes.includes(this.selectedCancerTypes[0]) | !this.cellSpecificCancerTypes.includes(this.selectedCancerTypes[1])) {
                    this.selectedCancerTypes = this.cellSpecificCancerTypes.slice(0, 2);
                }
                this.handleCancerTypeSelectionChange();
            } catch (error) {
                console.error('Error fetching cell-specific cancer types:', error);
            }
        },
        filterGenesForComparison(search) {
            if (search.length < 2) {
                this.filteredGenesForComparison = []; // 输入少于两个字符时不显示任何选项
            } else {
                const searchLower = search.toLowerCase();
                this.filteredGenesForComparison = this.commonGenes.filter(gene =>
                    gene.toLowerCase().includes(searchLower)
                );
            }
            console.log('filtered data:', this.filteredGenesForComparison);
        },
        async fetchGeneForComparisonExpressions({ signal }) {
            this.isFetchingGeneForComparison = true;
            console.log('Fetching gene for comparison expressions started');

            try {
                const dataByCategory = {};
                const requests = this.selectedCancerTypes.map(async (cancerType) => {
                    console.log(`Processing cancer type: ${cancerType}`);
                    dataByCategory[cancerType] = [];

                    try {
                        const [barcodesResponse, expressionResponse] = await Promise.all([
                            axios.get(`${process.env.VUE_APP_API_BASE_URL}/barcodes`, {
                                params: {
                                    cellType: this.selectedCellType,
                                    cancerType: cancerType
                                },
                                signal
                            }),
                            axios.get(`${process.env.VUE_APP_API_BASE_URL}/scexpressions`, {
                                params: {
                                    scDataset: cancerType,
                                    scGenes: this.selectedGeneForComparison
                                },
                                responseType: 'arraybuffer',
                                signal
                            })
                        ]);

                        const barcodes = new Set(barcodesResponse.data.barcodes);

                        try {
                            const decompressedData = pako.ungzip(new Uint8Array(expressionResponse.data), { to: 'string' });

                            const rows = decompressedData.split('\n').slice(0, -1);
                            rows.forEach(row => {
                                const [barcode, value] = row.split('\t');
                                if (barcodes.has(barcode) && value != 0) {
                                    dataByCategory[cancerType].push(parseFloat(value));
                                }
                            });
                        } catch (decompressionError) {
                            console.error(`Error decompressing data for cancer type ${cancerType}:`, decompressionError);
                        }
                    } catch (requestError) {
                        console.error(`Error fetching data for cancer type ${cancerType}:`, requestError);
                    }
                });

                await Promise.all(requests);
                const chart = echarts.init(this.$refs.comparisonBoxplot);
                const title = `Box Plot of ${this.selectedGeneForComparison} Expression in ${this.selectedCellType} (excluding zero values)`;
                this.renderBulkBoxplot(dataByCategory, chart, title);
            } catch (error) {
                this.isFetchingGeneForComparison = false;
                if (error.name === 'AbortError') {
                    console.log('Fetch aborted');
                } else {
                    console.error('Error fetching gene for comparison:', error);
                }
            } finally {
                this.isFetchingGeneForComparison = false;
            }
        },
        handleDatasetChange() {
            if (this.fetchSCGenesAbortController) {
                this.fetchSCGenesAbortController.abort();
            }
            this.fetchSCGenesAbortController = new AbortController();
            this.fetchSCGenes({ signal: this.fetchSCGenesAbortController.signal });
        },
        handleGeneChange() {
            if (this.fetchSCExpressionsAbortController) {
                this.fetchSCExpressionsAbortController.abort();
            }
            this.fetchSCExpressionsAbortController = new AbortController();
            if (this.selectedSCGene) {
                this.fetchSCExpressions({ signal: this.fetchSCExpressionsAbortController.signal });
            }
        },
        async handleCancerTypeSelectionChange() {
            try {
                let selectedCancerTypes = new Set();

                const responses = await Promise.all(this.selectedCancerTypes.map(cancerType =>
                    axios.get(`${process.env.VUE_APP_API_BASE_URL}/scgenes`, {
                        params: {
                            scDataset: cancerType
                        }
                    })
                ));

                const allGenes = responses.map(response => new Set(response.data.scGenes));

                if (allGenes.length > 0) {
                    selectedCancerTypes = allGenes[0];

                    for (let i = 1; i < allGenes.length; i++) {
                        selectedCancerTypes = new Set([...selectedCancerTypes].filter(x => allGenes[i].has(x)));
                    }
                }
                this.commonGenes = [...selectedCancerTypes];
                this.selectedGeneForComparison = this.commonGenes[0];
                this.handleGeneForComparisonChange();
            } catch (error) {
                console.error('Error fetching genes:', error);
            }
        },
        handleGeneForComparisonChange() {
            if (this.selectedGeneForComparison) {
                const controller = new AbortController();
                this.fetchGeneForComparisonExpressions({ signal: controller.signal });
            }
        },
        adjustScatterPlotSize() {
            this.$nextTick(() => {
                const scatterPlot = this.$refs.scScatter;
                if (scatterPlot) {
                    const width = scatterPlot.clientWidth;
                    const height = scatterPlot.clientHeight;
                    const size = Math.min(width, height);
                    const chart = echarts.init(scatterPlot);
                    chart.resize();
                    chart.setOption({
                        grid: {
                            width: size,
                            height: size
                        }
                    });
                }
            });
        },
        disposeCharts() {
            if (this.scatterChart) {
                this.scatterChart.dispose();
                this.scatterChart = null;
            }
            if (this.boxplotChart) {
                this.boxplotChart.dispose();
                this.boxplotChart = null;
            }
            if (this.comparisonBoxplotChart) {
                this.comparisonBoxplotChart.dispose();
                this.comparisonBoxplotChart = null;
            }
            if (this.bulkBoxplotChart) {
                this.bulkBoxplotChart.dispose();
                this.bulkBoxplotChart = null;
            }
        },
    }
};
</script>


<style>
.dropdown-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 5%;
}

select,
.vue-select {
    font-size: 16px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.dropdown-select {
    flex: 0 0 200px;
}

.dropdown-vue-select {
    flex: 1;
}

.vue-select input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.vue-select .vue-select-dropdown {
    max-height: 200px;
    overflow-y: auto;
}

.vs__selected {
    border: none;
    background-color: white;
}
</style>