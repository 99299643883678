<template>
  <div class="contact-container">
    <div class="contact-text">
      <p>
        <strong>Corresponding Author:</strong> <br>
        Shao Li, MD, PhD, Prof.
      </p>
      <p>
        MOE Key Laboratory of Bioinformatics, TCM-X Centre/Bioinformatics Division, BNRIST<br>
        Tsinghua University<br>
        Beijing, 100084<br>
        P. R. China
      </p>
      <p>
        Tel: <a href="tel:+861062797035">+86 10 62797035</a><br>
        Fax: <a href="fax:+861062786911">+86 10 62786911</a><br>
        E-mail: <a href="mailto:shaoli@mail.tsinghua.edu.cn">shaoli@mail.tsinghua.edu.cn</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactPage',
};
</script>

<style scoped>
.contact-container {
  display: flex;
  justify-content: center; /* 居中容器，但文本部分左对齐 */
  padding: 20px;
}

.contact-text {
  text-align: left; /* 左对齐文本 */
  font-size: 36px; /* 合适的字体大小 */
  line-height: 1.6; /* 行距更舒适 */
  max-width: 1200px; /* 控制最大宽度，让内容更美观 */
}

.contact-text a {
  color: blue;
  text-decoration: none;
}

.contact-text a:hover {
  text-decoration: underline; /* 鼠标悬停时显示下划线 */
}
</style>
