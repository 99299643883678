<template>
    <div>
        <h1>Spatial Transcriptional Data Analysis</h1>
        <div class="dropdown-container">
            <select v-model="selectedSTDataset" @change="handleDatasetChange" v-if="stDatasets.length"
                class="dropdown-select">
                <option disabled value="">Select</option>
                <option v-for="stDataset in stDatasets" :key="stDataset">{{ stDataset }}</option>
            </select>
            <select v-model="selectedSTImage" @change="handleImageChange" v-if="stImages.length"
                class="dropdown-select">
                <option disabled value="">Select</option>
                <option v-for="stImage in stImages" :key="stImage">{{ stImage }}</option>
            </select>
            <v-select v-model="selectedSTGene" :options="filteredSTGenes" @search="filterSTGenes"
                @change="fetchSTExpressions" placeholder="Type to search for genes" :reduce="gene => gene"
                :input-debounce="300" class="dropdown-select" :disabled="!selectedSTDataset" />
            <span v-if="!stGenes.length" class="dropdown-warning">
                This dataset is empty, please select another one.
            </span>
        </div>
        <div class="charts-container">
            <div class="chart-container">
                <h2>Expression Profile</h2>
                <div ref="stChart" style="width: 600px; height: 600px;"></div>
            </div>
            <div class="chart-container">
                <h2>Tissue Annotation/Expression</h2>
                <div ref="tissueChart" style="width: 600px; height: 600px;"></div>
                <div ref="legendContainer" class="legend-container"></div>
            </div>
        </div>
        <div class="opacity-slider-container">
            <label for="opacitySlider">Opacity:</label>
            <input type="range" id="opacitySlider" min="0" max="1" step="0.01" v-model="opacity" @input="updateOpacity">
            <span>{{ opacity }}</span>
        </div>
        <h2>CNV profiles and clone tree (CNV loci)</h2>
        <h2>Spatial TME analysis</h2>
    </div>
</template>

<script>
import axios from 'axios';
import * as echarts from 'echarts';
import vSelect from 'vue3-select';
import pako from 'pako';
import 'vue3-select/dist/vue3-select.css';

export default {
    components: { vSelect },
    data() {
        return {
            stDatasets: [],
            stGenes: [],
            filteredSTGenes: [],
            selectedSTDataset: '',
            stImages: [],
            selectedSTImage: '',
            selectedSTGene: '',
            stUMAPTemplate: [],
            stUMAP: [],
            maxValue: 10,
            opacity: 0.2,
        };
    },
    mounted() {
        this.fetchSTDatasets();
    },
    methods: {
        async fetchSTDatasets() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/stdatasets`);
                this.stDatasets = response.data.stDatasets;
                this.selectedSTDataset = this.stDatasets[0];
                this.handleDatasetChange();
            } catch (error) {
                console.error('Error fetching cancer types:', error);
            }
        },
        filterSTGenes(search) {
            if (search.length < 2) {
                this.filteredSTGenes = [];
            } else {
                const searchLower = search.toLowerCase();
                this.filteredSTGenes = this.stGenes.filter(gene =>
                    gene.toLowerCase().includes(searchLower)
                );
            }
        },
        async handleDatasetChange() {
            try {
                const imageResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/stimages`, {
                    params: {
                        stDataset: this.selectedSTDataset
                    }
                });
                this.stImages = imageResponse.data.stImages;
                this.selectedSTImage = this.stImages[0];
                this.handleImageChange();
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        },
        loadImage(url) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => {
                    this.backgroundImageWidth = img.width;
                    this.backgroundImageHeight = img.height;
                    resolve();
                };
                img.onerror = reject;
                img.src = url;
            });
        },
        async handleImageChange() {
            try {
                const geneResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/stgenes`, {
                    params: {
                        stDataset: this.selectedSTDataset
                    }
                });
                this.stGenes = geneResponse.data.stGenes;
                this.selectedSTGene = this.stGenes[0];
                this.filteredSTGenes = [];

                const umapResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/stlocation`, {
                    params: {
                        stDataset: this.selectedSTDataset,
                        stImage: this.selectedSTImage
                    },
                    responseType: 'arraybuffer'
                });
                const decompressedData = pako.ungzip(new Uint8Array(umapResponse.data), { to: 'string' });
                const rows = decompressedData.trim().split('\n');
                const headers = rows[0].split('\t');

                const imageResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/stimage`, {
                    params: {
                        stDataset: this.selectedSTDataset,
                        stImage: this.selectedSTImage
                    },
                    responseType: 'arraybuffer'
                });

                const blob = new Blob([imageResponse.data], { type: 'image/png' });
                this.imageUrl = URL.createObjectURL(blob);

                await this.loadImage(this.imageUrl);

                this.$refs.stChart.style.width = `${this.backgroundImageWidth}px`;
                this.$refs.stChart.style.height = `${this.backgroundImageHeight}px`;
                this.$refs.tissueChart.style.width = `${this.backgroundImageWidth}px`;
                this.$refs.tissueChart.style.height = `${this.backgroundImageHeight}px`;

                this.stUMAPTemplate = rows.slice(1).map(row => {
                    const values = row.split('\t');
                    const item = headers.reduce((obj, header, index) => {
                        obj[header] = values[index];
                        return obj;
                    }, {});
                    item['expression'] = 0;
                    item['col'] = this.backgroundImageHeight.toFixed(4) - item['col'];
                    return item;
                });
                this.fetchSTExpressions();
                this.renderSTChart(echarts.init(this.$refs.tissueChart), this.stUMAPTemplate, 'cluster', this.imageUrl);
            } catch (error) {
                console.error('Error fetching metadata:', error);
            }
        },
        async fetchSTExpressions() {
            try {
                this.stUMAP = this.stUMAPTemplate.map(item => ({ ...item }));
                const stResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/stexpressions`, {
                    params: {
                        stDataset: this.selectedSTDataset,
                        stGene: this.selectedSTGene
                    },
                    responseType: 'arraybuffer'
                });

                const decompressedData = pako.ungzip(new Uint8Array(stResponse.data), { to: 'string' });
                const lines = decompressedData.split('\n');
                const headers = ['barcode', 'expression'];
                const stExpressions = lines.slice(1).map(line => {
                    const values = line.split('\t').slice(0, 2);
                    const expression = {};
                    headers.forEach((header, i) => {
                        expression[header] = values[i];
                    });
                    return expression;
                });

                const stExpressionMap = new Map();
                stExpressions.forEach(({ barcode, expression }) => {
                    stExpressionMap.set(barcode, expression);
                });
                this.stUMAP.forEach(item => {
                    if (stExpressionMap.has(item.barcode)) {
                        item.expression = stExpressionMap.get(item.barcode);
                    }
                });
                this.renderSTChart(echarts.init(this.$refs.stChart), this.stUMAP, 'expression', this.imageUrl);
            } catch (error) {
                console.error('Error fetching cancer genes:', error);
            }
        },
        renderSTChart(chart, values, type, backgroundImage) {
            this.maxValue = values.length ? Math.max(...values.map(item => item[type])) : this.maxValue;
            const maxValue = Math.ceil(this.maxValue);
            let colorMap = {};
            if (type === 'cluster') {
                const categories = [...new Set(values.map(item => item['cluster']))];
                const colors = Array.from({ length: categories.length }, (_, i) =>
                    echarts.color.modifyHSL('#ff0000', i * (360 / categories.length), 0.7, 0.7)
                );
                colorMap = categories.reduce((acc, category, index) => {
                    acc[category] = colors[index];
                    return acc;
                }, {});
                this.createLegend(colorMap);
            }
            const option = {
                grid: {
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    containLabel: false
                },
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        return type === 'expression' ? `${params.marker} ${params.data.value[2].toFixed(4)}: [${params.data.value[0]}, ${params.data.value[1]}]` : `${params.marker} Cluster ${params.data.value[2]}: [${params.data.value[0]}, ${params.data.value[1]}]`;
                    }
                },
                xAxis: {
                    type: 'value',
                    min: 0,
                    max: this.backgroundImageWidth,
                    scale: false,
                    axisLine: { onZero: true },
                    show: false
                },
                yAxis: {
                    type: 'value',
                    min: 0,
                    max: this.backgroundImageHeight,
                    scale: false,
                    axisLine: { onZero: true },
                    show: false
                },
                visualMap: {
                    show: type === 'expression',
                    type: 'continuous',
                    calculable: true,
                    min: 0,
                    max: maxValue,
                    color: ['red', 'orange', 'yellow', 'grey'],
                    textStyle: {
                        color: '#333'
                    }
                },
                series: [
                    {
                        name: 'Gene Expression',
                        type: 'scatter',
                        data: values.map(item => ({
                            value: [
                                parseFloat(item['row']),
                                parseFloat(item['col']),
                                parseFloat(item[type])
                            ],
                            symbolSize: 6.25,
                            symbol: 'path://M 3 1 L 5 1 L 6 3 L 5 5 L 3 5 L 2 3 Z',
                            itemStyle: {
                                color: type === 'cluster' ? colorMap[item['cluster']] : undefined,
                                opacity: this.opacity
                            }
                        }))
                    }
                ],
                graphic: {
                    type: 'image',
                    id: 'background',
                    left: 'center',
                    top: 'center',
                    z: -1,
                    bounding: 'all',
                    style: {
                        image: backgroundImage,
                        width: this.backgroundImageWidth,
                        height: this.backgroundImageHeight,
                        opacity: 1
                    }
                }
            };
            chart.setOption(option);
        },
        createLegend(colorMap) {
            const legendContainer = this.$refs.legendContainer;
            legendContainer.innerHTML = '';

            Object.keys(colorMap).forEach(category => {
                const legendItem = document.createElement('span');
                legendItem.style.display = 'flex';
                legendItem.style.alignItems = 'center';
                legendItem.style.marginBottom = '5px';
                legendItem.style.marginRight = '15px';

                const colorBox = document.createElement('span');
                colorBox.style.width = '25px';
                colorBox.style.height = '14.4px';
                colorBox.style.backgroundColor = colorMap[category];
                colorBox.style.marginRight = '10px';
                colorBox.style.borderRadius = '3px';

                const labelText = document.createElement('span');
                labelText.textContent = category;
                labelText.style.fontSize = '12px';
                labelText.style.fontFamily = 'Helvetica, sans-serif';
                labelText.style.fontWeight = 'semibold';

                legendItem.appendChild(colorBox);
                legendItem.appendChild(labelText);
                legendContainer.appendChild(legendItem);
            });
        },
        updateOpacity() {
            this.renderSTChart(echarts.init(this.$refs.tissueChart), this.stUMAPTemplate, 'cluster', this.imageUrl);
            this.renderSTChart(echarts.init(this.$refs.stChart), this.stUMAP, 'expression', this.imageUrl);
        },
    }
};
</script>

<style>
.dropdown-container {
    margin-bottom: 20px;
}

.dropdown-select {
    width: 200px;
    padding: 5px;
}

.charts-container {
    display: flex;
    justify-content: space-around;
}

.chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 48%;
}

.chart {
    width: 100%;
    height: 600px;
}

.chart-container {
    position: relative;
}

.legend-container {
    width: 100%;
    text-align: center; 
    margin-top: 10px;
}
</style>