<template>
  <header>
    <img src="../assets/images/banner.png" alt="header image" class="header_image">
    <nav class="navbar">
      <ul class="nav-list">
        <li v-for="(item, index) in menuItems" :key="index" class="nav-item">
          <router-link :to="item.to" class="nav-link">
            <font-awesome-icon :icon="item.icon" alt="Icon" class="nav-icon" />
            <span class="nav-text">{{ item.text }}</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'HeaderNavbar',
  data() {
    return {
      menuItems: [
        { to: '/', icon: ['fas', 'home'], text: 'Home' },
        { to: '/analyze/abundance', icon: ['fas', 'chart-line'], text: 'Cell Abundance' },
        { to: '/analyze/state', icon: ['fas', 'chart-line'], text: 'Cell State' },
        { to: '/analyze/expression', icon: ['fas', 'chart-line'], text: 'Cell Expression' },
        { to: '/analyze/st', icon: ['fas', 'chart-line'], text: 'ST Data' },
        { to: '/contact', icon: ['fas', 'envelope'], text: 'Help & Contact' }
      ]
    };
  }
};
</script>

<style scoped>
.header_image {
  width: 100%;
  height: 100px;
  max-height: 100%;
  object-fit: cover;
  object-position: center;
  vertical-align: top;
}

.navbar {
  background-color: rgb(10, 11, 27);
  display: flex;
  justify-content: center;
}

.nav-list {
  list-style: none;
  padding: 0;
  display: flex;
}

.nav-item {
  padding: 30px;
}

.nav-link {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.nav-icon {
  margin-right: 5px;
  width: 30px;
  height: 30px;
}

.nav-text {
  color: white;
  font-size: 30px
}
</style>