import {createRouter, createWebHashHistory } from 'vue-router';
import HomePage from '../views/Home.vue';
import ContactPage from '../views/Contact.vue';
import AbundancePage from '../views/AbundancePage.vue';
import StatePage from '../views/StatePage.vue';
import ExpressionPage from '../views/ExpressionPage.vue';
import STPage from '../views/STPage.vue';

const routes=[
    {
        path:'/',
        name:'HomePage',
        component:HomePage
    },
    {
        path:'/contact',
        name:'ContactPage',
        component:ContactPage
    },
    {
        path:'/analyze/abundance',
        name:'AbundancePage',
        component:AbundancePage
    },
    {
        path:'/analyze/state',
        name:'StatePage',
        component:StatePage
    },
    {
        path:'/analyze/expression',
        name:'ExpressionPage',
        component:ExpressionPage
    },
    {
        path:'/analyze/st',
        name:'STPage',
        component:STPage
    },
]
const router =createRouter({
    history:createWebHashHistory(),
    routes
})

export default router