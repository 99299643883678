import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { 
    faUserSecret, faHome, faSearch, faDownload, 
    faChartLine, faBook, faEnvelope, faFolderOpen 
} from '@fortawesome/free-solid-svg-icons';

library.add(faUserSecret, faHome, faSearch, faDownload, faChartLine, faBook, faEnvelope, faFolderOpen);
const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(router);
app.mount('#app');
